import { SearchListStructure } from '@/utils';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import CompanyEditor from './companyEditor';
import CompanyInfoModel from './model';

export default observer(() => {
  const store = useMemo(() => new CompanyInfoModel(), []);
  const { mainModel, editorModel } = store;
  return (
    <>
      <SearchListStructure store={mainModel} />
      <CompanyEditor store={editorModel} />
    </>
  );
});
