import AMapLoader from '@amap/amap-jsapi-loader';
import { type FormInstance, message } from 'antd';
import { action, observable, toJS } from 'mobx';
import type { BaseData } from '../../../../utils';
import { request } from '../../../../utils';
import type { CompanyAddressVO, CompanyDTO, CompanyPhotoVO, CompanyVO } from '../type';
interface Option {
  value: string;
  label: string;
  children?: Option[];
}
/**
 * 编辑框Modal
 */
class Modal {
  /**
   * Modal是否可见
   */
  @observable public visible = false;
  /**
   * 保存Loading
   */
  @observable public saveLoading = false;
  /**
   * Form的ref
   */
  public ref: FormInstance;
  /**
   * 设置Form的ref
   * @param ref Form的ref
   */
  public setRef = (ref: FormInstance) => {
    this.ref = ref;
  };
  /**
   * 取消Modal
   */
  @action public onCancel = () => {
    this.visible = false;
    this.ref.resetFields();
  };
}

/**
 * 地址Modal
 */
export class AddressModel extends Modal {
  constructor(editorModel?: EditorModel) {
    super();
    this.editorModel = editorModel;
  }

  /**
   * 编辑框Modal的Model
   */
  @observable public editorModel: EditorModel;
  /**
   * 高德地图弹窗
   */
  public amapModal = new AmapModel(this);
  /**
   * 地址Modal的options
   */
  @observable public options: Option[];
  /**
   * 打开Modal
   */
  @action public onOpen = async (employerId: string) => {
    this.visible = true;
    this.saveCallBack = null;
    this.employerId = employerId;
  };
  @observable public employerId: string;
  public saveCallBack?: () => void;
  /**
   * 保存Modal
   */
  @action public onFinish = async () => {
    if (this.saveLoading) {
      return;
    }
    this.saveLoading = true;
    try {
      const employerId = this.employerId;
      const values = this.ref?.getFieldsValue(true);
      const res = await request<BaseData<any>>({
        url: '/pbb/platform/company/create/address',
        method: 'POST',
        data: {
          ...values,
          employerId,
        },
      });
      this.onCancel();
      message.success('新增地址成功');
      this.ref?.resetFields();
      this.amapModal.options = [];
      this.amapModal.ref?.resetFields();
      if (this.saveCallBack) {
        this?.saveCallBack();
        return;
      }
      this.editorModel?.getCompanyAddressList();
    } catch (error) {
      console.log(error);
    } finally {
      this.saveLoading = false;
    }
  };
  /**
   * 选择地址的回调函数
   * @param values 选择的地址信息
   */
  @action public callbackAddress = async (values: Record<string, any>) => {
    console.log(values);
    const { location } = values;
    const [longitude, latitude] = location.split(',');
    interface ProvinceCityCounty {
      provinceId: string;
      province: string;
      cityId: string;
      city: string;
      countyId: string;
      county: string;
    }
    try {
      const res = await request<BaseData<ProvinceCityCounty>>({
        url: '/baseinfo/area/getProvinceCityCounty',
        method: 'GET',
        params: {
          latitude,
          longitude,
        },
      });
      this.ref?.setFieldsValue({
        cityId: res.data.cityId,
        cityName: res.data.city,
        countyId: res.data.countyId,
        countyName: res.data.county,
        provinceId: res.data.provinceId,
        provinceName: res.data.province,
        point: {
          longitude,
          latitude,
        },
        info: `${values.district}${values.name}`,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

/**
 * 高德地图弹窗
 */
export class AmapModel extends Modal {
  constructor(addressModal?: AddressModel) {
    super();
    this.addressModal = addressModal;
  }
  addressModal: AddressModel;

  /**
   * 高德地图key
   */
  private readonly key = 'e7c37ae0edbee391e2c06f40545df69d';
  /**
   * 高德地图JsKey
   */
  private readonly jsKey = 'e4544a8591e1b3cbbf94f204ec2987ca';
  /**
   * 高德地图
   */
  public map: any;
  /**
   * 高德地图实例
   */
  public amap: any;

  /**
   * 打开高德地图弹窗
   */
  @action public onOpen = () => {
    this.visible = true;
    if (!this.amap) {
      AMapLoader.load({
        key: this.key,
        version: '2.0',
        plugins: ['AMap.Scale', 'AMap.PlaceSearch', 'AMap.AutoComplete'],
      })
        .then((AMap: any) => {
          this.amap = AMap;
          this.map = new AMap.Map('containerMap', {
            viewMode: '3D', // 是否为3D地图模式
            zoom: 15, // 初始化地图级别
            mapStyle: 'amap://styles/normal', // 设置地图的显示样式
          });
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  };

  /**
   * 高德地图弹窗确定
   */
  @action public onFinish = async () => {
    const values = this.ref.getFieldValue('address');
    if (!values) {
      message.error('请选择一个地址');
    } else {
      this.visible = false;
      this?.addressModal.callbackAddress(values);
    }
  };
  /**
   * 高德地图模糊搜索数据
   */
  @observable public options: Record<string, any>[] = [];

  /**
   * 高德地图模糊搜索
   * @param keywords 搜索关键词
   */
  @action public onSearch = (keywords: string) => {
    fetch(`https://restapi.amap.com/v3/assistant/inputtips?key=${this.jsKey}&keywords=${keywords}`)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.info === 'OK') {
          this.options =
            data.tips
              ?.filter((item: Record<string, any>) => item.location?.length > 0)
              ?.map((item: Record<string, any>) => ({
                label: `${item.district}${item.name}`,
                value: `${item.district}${item.name}`,
                info: item,
              })) || [];
        } else {
          this.options = [];
        }
      });
  };

  /**
   *
   * @param id
   * @param options 选择的数据
   */
  @action public onSelect = (id: string, options: Record<string, any>) => {
    const position = options.info?.location.split(',') || [0, 0];
    const marker = new this.amap.Marker({
      icon: 'https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png',
      position,
    });
    this.map.clearMap();
    this.map.setCenter(position);
    this.map.add(marker);
    this.ref?.setFieldValue('address', options.info);
  };
}

/**
 * 编辑模型
 */
export default class EditorModel extends Modal {
  /**
   * 地址弹窗
   */
  public addressModal = new AddressModel(this);

  /**
   * 人员规模
   */
  public readonly scaleType = {
    1: '0-20',
    2: '20-99',
    3: '100-499',
    4: '500+',
  };

  /**
   * 是否可以编辑
   */
  @observable public isEdit = false;

  /**
   * 企业信息
   */
  @observable public companyData: CompanyVO | (CompanyVO & { images: CompanyPhotoVO[]; videos: CompanyPhotoVO[] }) = {};

  /**
   * 是否可以删除地址
   */
  @observable public isDelAdd = false;

  /**
   * 是否可以删除图片
   */
  @observable public isDelImg = false;

  /**
   * 是否可以删除视频
   */
  @observable public isDelVideo = false;

  // 保存公司回调，供其他地方使用
  @observable public saveCallBack: (params?: Record<string, any>) => void | null;

  /**
   * 打开
   * @param id 企业id
   */
  @action public onOpen = (id: string) => {
    this.visible = true;
    this.getCompanyDeatil(id);
  };

  /**
   * 获取企业信息
   * @param id 企业id
   */
  @action public getCompanyDeatil = async (id: string) => {
    try {
      this.detailLoading = true;
      const res = await request<BaseData<CompanyVO>>({
        url: '/pbb/platform/company/getInfo',
        method: 'POST',
        data: {
          id,
        },
      });
      setTimeout(() => {
        // 把CompanyPhotoVO 的数据  通过 fileType 1和2 分成 images和videos 两个数组
        const images: CompanyPhotoVO[] = [];
        const videos: CompanyPhotoVO[] = [];
        res.data.photoVOS.forEach((item: CompanyPhotoVO) => {
          if (item.fileType === 1) {
            images.push(item); // 图片
          } else if (item.fileType === 2) {
            videos.push(item); // 视频
          }
        });
        this.photoDTOS = toJS(res.data.photoVOS);
        this.companyData = {
          ...res.data,
          images,
          videos,
        };
        this.ref?.setFieldsValue(this.companyData);
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.detailLoading = false;
    }
  };

  /**
   * 企业地址列表
   */
  @observable public companyAddressList: CompanyAddressVO[] = [];

  /**
   * 获取企业地址列表
   */
  @action public getCompanyAddressList = async () => {
    try {
      const res = await request<BaseData<CompanyAddressVO[]>>({
        url: '/pbb/platform/company/address/list',
        method: 'POST',
        data: {
          id: this.companyData.id,
        },
      });
      this.companyAddressList = res.data;
      this.ref?.setFieldsValue({
        addressVOList: this.companyAddressList,
      });
    } catch (error) {}
  };

  /**
   * 删除地址
   * @param key 选择的地址
   */
  @action public removeAddress = async (key: number) => {
    console.log(key);

    if (this.isDelAdd) {
      return;
    }
    this.isDelAdd = true;

    const id = this.ref?.getFieldValue('addressVOList')?.[key]?.id;
    try {
      const res = await request<BaseData<any>>({
        url: '/pbb/platform/company/delete/address',
        method: 'POST',
        data: { id },
      });
      message.success('删除成功');
      this.getCompanyAddressList();
    } catch (error) {
    } finally {
      this.isDelAdd = false;
    }
  };

  /**
   * 删除图片
   * @param id 图片id
   */
  @action public removePhoto = async (id: number) => {
    if (this.isDelImg) {
      return;
    }
    this.isDelImg = true;
    try {
      const res = await request<BaseData<CompanyVO>>({
        url: '/pbb/platform/company/delete/photo',
        method: 'POST',
        data: { id },
      });
      this.refreshPhoto();
    } catch (error) {
    } finally {
      this.isDelImg = false;
    }
  };

  /**
   * 删除图片
   */
  @action refreshPhoto = async () => {
    const res = await request<BaseData<CompanyPhotoVO[]>>({
      url: '/pbb/platform/get/company/photo/info',
      method: 'POST',
      data: {
        id: this.companyData.id,
      },
    });
    const images: CompanyPhotoVO[] = [];
    const videos: CompanyPhotoVO[] = [];
    res.data.forEach((item: CompanyPhotoVO) => {
      if (item.fileType === 1) {
        images.push(item); // 图片
      } else if (item.fileType === 2) {
        videos.push(item); // 视频
      }
    });
    this.ref?.setFieldsValue({
      images,
      videos,
    });
  };

  /**
   * 取消
   */
  @action public onCancel = () => {
    this.visible = false;
    this.isEdit = false;
    this.ref?.resetFields();
    this.saveCallBack = null;
  };

  /**
   * 上传
   * @param values 上传的数据
   */
  public beforeFinish = (values: Partial<CompanyDTO>) => {
    console.log(values);
    const newValues = {
      businessDTOS: values.businessVOS,
      // businessLicenseUrl: 'https://muted-coliseum.com/',
      id: values.id,
      name: values.name,
      scaleType: values.scaleType,
      intro: values.intro,
    };
    this.onFinish(newValues);
  };

  /**
   * 上传
   * @param values 上传的数据
   */
  @observable public onMediaFinish = async (values: Partial<CompanyDTO>) => {
    if (this.saveLoading) {
      return;
    }
    this.saveLoading = true;
    try {
      const res = await request<BaseData<any>>({
        url: '/pbb/platform/company/info/update',
        method: 'POST',
        data: { ...values, id: this.companyData.id },
      });
      message.success('上传成功');
      this.refreshPhoto();
    } catch (error) {
      console.log(error);
      throw new Error();
    } finally {
      this.saveLoading = false;
    }
  };

  /**
   * 上传
   * @param values 上传的数据
   */
  @action public onFinish = async (values: Partial<CompanyDTO>) => {
    if (this.saveLoading) {
      return;
    }
    this.saveLoading = true;
    try {
      const res = await request<BaseData<any>>({
        url: '/pbb/platform/company/info/update',
        method: 'POST',
        data: { ...values, id: this.companyData.id },
      });
      this.saveCallBack?.({ ...values });
      this.onCancel();
      message.success('编辑成功');
    } catch (error) {
      console.log(error);
    } finally {
      this.saveLoading = false;
    }
  };

  /**
   * 最开始的相册
   */
  public photoDTOS: CompanyPhotoVO[] = [];
  /**
   * 详情Loading
   */
  @observable public detailLoading: boolean;
}
