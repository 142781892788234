import type { PlatformSysBusinessCardVO } from '@/pages/user/businessCardManagement/type';
import {
  type IMainSubStructureModel,
  type NormalProgrammeParams,
  type PaginationData,
  RenderByPermission,
  SearchListModal,
  request,
} from '@/utils';
import { Button, Tooltip } from 'antd';
import EditorModel from './companyEditor/model';

/**
 * 企业信息Model
 */
export default class CompanyInfoModel {
  /**
   * 构造函数
   */
  constructor() {
    this.mainModel?.grid?.gridModel?.onQuery();
  }

  /**
   * 编辑框Modal
   */
  public editorModel = new EditorModel();

  /**
   * 人员规模Dict
   */
  private readonly scaleType = {
    1: '0-20',
    2: '20-99',
    3: '100-499',
    4: '500+',
  };

  /**
   * 企业信息Grid
   */
  public grid: IMainSubStructureModel = {
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    grid: {
      columns: [
        {
          key: 'opreations',
          name: '操作',
          width: 150,
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');
            return (
              <div>
                <RenderByPermission permissionId={`${pageId}_21`}>
                  <Button
                    onClick={() => {
                      this.editorModel.onOpen(row.id);
                    }}
                    type="link"
                  >
                    详情
                  </Button>
                </RenderByPermission>
              </div>
            );
          },
        },
        {
          key: 'name',
          name: '企业名称',
          with: 200,
        },
        {
          key: 'companyAddressVOList',
          name: '企业地址',
          with: 200,
          formatter: ({ row }) => {
            const info = row.companyAddressVOList?.map((item: any) => item.info).join('， ');
            return <Tooltip title={info}>{info}</Tooltip>;
          },
        },
        {
          key: 'latestUpdateTime',
          name: '人员规模',
          formatter: ({ row }) => this.scaleType[row.scaleType || 0] || '',
        },
        {
          key: 'employerName',
          name: '雇主',
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      showPager: true,
      showPagination: true,
      showGridOrderNo: true,
      showSelectedTotal: false,
    },
    api: {
      /**
       * 查询
       * @param params
       */
      onQuery: (params) => {
        console.log(params);
        const { filterParams, ...rest } = params;
        const postParams = {
          ...filterParams,
          ...rest,
        };
        for (const k in postParams) {
          if (Array.isArray(postParams[k])) {
            postParams[k] = postParams[k].toString();
          }
        }

        return request<PaginationData<PlatformSysBusinessCardVO>>({
          url: '/pbb/platform/sys/company/info/list',
          method: 'POST',
          data: { ...postParams },
        });
      },
    },
  };

  /**
   * 企业信息搜索框
   */
  public programme: NormalProgrammeParams = {
    filterItems: [
      {
        type: 'input',
        field: 'name',
        label: '企业名称',
        placeholder: '请输入',
      },
    ],
  };

  /**
   * 企业信息SearchListModal
   */
  public mainModel = new SearchListModal({
    programme: this.programme,
    grid: this.grid,
  });
}
